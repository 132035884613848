









































import Vue from 'vue';
import { SwarmService } from '@/services/swarm.service';

import CreateItemDialog from '@/components/CreateItemDialog.vue';

interface ICreateItemDialog extends Vue {
	handleToggle: Function;
}

const DEFAULT_HEADERS = [
	{
		text: 'ID',
		align: 'left',
		sortable: true,
		value: '_id',
	},
	{
		text: 'Action',
		align: 'right',
		sortable: false,
		value: 'action',
	},
];

const TABLE_HEADERS = {
	packs: [
		{
			text: 'Name',
			align: 'left',
			sortable: true,
			value: 'name',
		},
		{
			text: 'Artist',
			align: 'left',
			sortable: true,
			value: 'artist',
		},
		{
			text: 'Genre',
			align: 'left',
			sortable: true,
			value: 'genre',
		},
		{
			text: 'Date Added',
			align: 'left',
			sortable: true,
			value: 'addedOn',
		},
		...DEFAULT_HEADERS,
	],
	artists: [
		{
			text: 'Name',
			align: 'left',
			sortable: true,
			value: 'name',
		},
		{
			text: 'Tags',
			align: 'left',
			sortable: true,
			value: 'tags',
		},
		...DEFAULT_HEADERS,
	],
	videos: [
		{
			text: 'Name',
			align: 'left',
			sortable: true,
			value: 'name',
		},
		{
			text: 'Title',
			align: 'left',
			sortable: true,
			value: 'title',
		},
		{
			text: 'Vimeo ID',
			align: 'left',
			sortable: true,
			value: 'vimeoId',
		},
		...DEFAULT_HEADERS,
	],
	images: [
		...DEFAULT_HEADERS,
	],
	subscriptions: [
		...DEFAULT_HEADERS,
	],
	users: [
		{
			text: 'Email',
			align: 'left',
			sortable: true,
			value: 'emailAddress',
		},
		...DEFAULT_HEADERS,
	],
	tags: [
		{
			text: 'Name',
			align: 'left',
			sortable: true,
			value: 'name',
		},
		...DEFAULT_HEADERS,
	],
};


export default Vue.extend({
	components: {
		CreateItemDialog
	},
	props: {
		collection: {
			type: String
		},
		schema: {
			type: Object
		}
	},
	computed: {
		headers() {
			switch (this.collection) {
				case 'packs':
					return TABLE_HEADERS.packs;
				case 'artists':
					return TABLE_HEADERS.artists;
				case 'videos':
					return TABLE_HEADERS.videos;
				case 'images':
					return TABLE_HEADERS.images;
				case 'subscriptions':
					return TABLE_HEADERS.subscriptions;
				case 'users':
					return TABLE_HEADERS.users;
				case 'tags':
					return TABLE_HEADERS.tags;
				default:
					break;
			}
			return [

			];
		},
	},
	data: () => ({
		items: [],
		search: '',
		loadedCollection: '',
		loading: false,
		editing: false,
		editItem: {},
	}),
	methods: {
		load() {
			this.editing = false;
			this.editItem = {};

			this.loading = true;
			SwarmService.getCollection(this.collection)
				.then((items) => {
					this.loadedCollection = this.collection;
					this.items = items;
				}).finally(() => {
					this.loading = false;
				});
		},
		handleEdit(item: any) {
			this.$emit('edit', {
				collection: this.collection,
				item
			});

			this.editing = true;
			this.editItem = item;

			const dialogRef = this.$refs.dialog as ICreateItemDialog;

			dialogRef.handleToggle();
		},
		handleDelete(item: any) {
      /* eslint-disable */
      if (window.confirm(`Are you sure you want to delete ${item.name}? This is irreversable.`)) {
        return this.deleteItem(item);
      }

      return;
		},
		handleCreate() {
			this.$emit('create', this.collection);
		},
		deleteItem(item: any) {
			const id = item._id;

			if (id) {
				this.loading = true;
				SwarmService.deleteItem(this.collection, id)
					.then(() => {
						this.load();
					})
					.finally(() => {
						this.loading = false;
					});
			}
		}
	},
	updated() {
		if (this.collection !== this.loadedCollection) {
			this.load();
		}
	},
	mounted() {
		this.load();
	}
});
